import styled from "styled-components";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import AssetHourglass from "@sablier/v2-assets/3d/hourglass-d2-gold.png";
import AssetShield from "@sablier/v2-assets/3d/shield-blue.png";
import AssetSafe from "@sablier/v2-assets/brands/safe.svg";
import { Button, Chain, Icon, Label } from "@sablier/v2-components/molecules";
import { chains, links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import nextDynamic from "next/dynamic";
import Image from "next/image";
import { Parallax } from "react-scroll-parallax";
import { Section as SectionPartial } from "~/landing/components/organisms";

const Underlay = nextDynamic(() => import("./Underlay"), { ssr: false });

const WrapperPartial = styled.div`
  position: relative;
  width: 100%;
`;

const Section = styled(SectionPartial)`
  padding-top: calc(${(props) => props.theme.sizes.edge} * 6);
  padding-bottom: calc(${(props) => props.theme.sizes.edge} * 2);
  & > div {
    padding: 0;
  }
  div[data-component="top"] {
    align-items: center;
    width: 100%;
    * {
      align-items: center;
      text-align: center;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  margin-top: calc(${(props) => props.theme.sizes.edge} * 4);
`;

const Cell = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    grid-column: span 1;
    justify-content: flex-start;

    &:nth-child(3) {
      padding-top: calc(${(props) => props.theme.sizes.edge} * 5);
    }
  }
`;

const Center = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;

const Box = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 2);
    width: calc(100% + 2px);
    padding: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    border: 2px solid ${(props) => props.theme.colors.dark200};
    border-radius: 0;
  }
`;

const Heading = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    color: ${(props) => props.theme.colors.white};
    & > p {
      ${(props) => props.theme.styles.textTitle}
      & {
        font-weight: 600;
        font-size: 18pt;
        line-height: 140%;
      }
    }
  }
`;

const Group = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
  }
`;

const Title = styled(Label)`
  color: ${(props) => props.theme.colors.gray100};
`;

const Description = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraph}
    & {
      color: ${(props) => props.theme.colors.gray300};
    }
  }
`;

const Chains = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  width: 100%;
`;

const Bubble = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-shrink: 0;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.dark150};
    & > * {
      mix-blend-mode: luminosity;
    }
  }
`;

const SafeReady = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    --size: 22px;
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 3);
    margin-bottom: calc(${(props) => props.theme.sizes.edge} * 3);
    color: ${(props) => props.theme.colors.secondaryMiddle};
  }
`;

const Safe = styled.div`
  position: relative;
  width: 100px;
  height: 42px;
  margin-top: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  margin-left: -4px;

  img {
    object-fit: contain;
  }
`;

const Hourglass = styled(Parallax)`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    justify-content: center;
    width: 80%;
    height: 200px;
    margin: 0 auto;
    img {
      object-fit: contain;
    }
  }
`;

const Actions = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    width: 100%;
  }
`;

const Row = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    width: 100%;
  }
`;

const Shielded = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    justify-content: center;
    width: 100%;
    margin: calc(${(props) => props.theme.sizes.edge} * 5 / 2) 0;
  }
`;

const Shield = styled(Parallax)`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    justify-content: center;
    width: 80%;
    height: 180px;
    margin: 0 auto;
    img {
      object-fit: contain;
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxLG} {
    ${Grid} {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  ${(props) => props.theme.medias.maxSM} {
    ${Section} {
      padding-top: calc(${(props) => props.theme.sizes.edge} * 6);
      padding-bottom: calc(${(props) => props.theme.sizes.edge} * 0);
    }
    ${Grid} {
      grid-template-columns: repeat(1, 1fr);
      margin-top: 0;
    }
    ${Cell} {
      &:nth-child(2) {
        padding-top: calc(${(props) => props.theme.sizes.edge} * 2);
      }
      &:nth-child(3) {
        padding-top: calc(${(props) => props.theme.sizes.edge} * 2);
      }
    }

    ${Box} {
      gap: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
      width: calc(100%);
      padding: calc(${(props) => props.theme.sizes.edge} * 1);
      border-right: 0;
      border-left: 0;
    }

    ${SafeReady} {
      margin-bottom: 0;
    }
  }
`;

const list = [
  chains.mainnet.chainId,
  chains.arbitrum.chainId,
  chains.avalanche.chainId,
  chains.base.chainId,
  chains.bsc.chainId,
  chains.gnosis.chainId,
  chains.optimism.chainId,
  chains.polygon.chainId,
  chains.scroll.chainId,
];

function Develop() {
  const { t } = useT();

  return (
    <Wrapper id={"develop"}>
      <Section
        color={"secondaryMiddle"}
        title={t("landing.develop.title")}
        label={t("landing.develop.label")}
      >
        <Grid>
          <Cell>
            <Box>
              <Heading>
                <p>{t("landing.develop.explore.title")}</p>
              </Heading>
              <Group>
                <Title value={t("landing.develop.explore.chains.title")} />
                <Description>
                  <p>{t("landing.develop.explore.chains.description")}</p>
                </Description>
                <Chains>
                  {list.map((chainId) => (
                    <Bubble key={chainId}>
                      <Chain id={chainId} isNameShown={false} size={24} />
                    </Bubble>
                  ))}
                </Chains>
              </Group>
              <Group>
                <Title value={t("landing.develop.explore.safe.title")} />
                <Description>
                  <p>{t("landing.develop.explore.safe.description")}</p>
                </Description>
                <SafeReady>
                  <Safe>
                    <Image
                      alt={"Safe multisig logo"}
                      src={AssetSafe}
                      fill
                      sizes={"100vw"}
                    />
                  </Safe>

                  <Icon value={CheckCircleIcon} />
                </SafeReady>
              </Group>
              <Group>
                <Actions>
                  <Button
                    accent={"dark150"}
                    appearance={"outline"}
                    title={t("landing.develop.explore.actions.safe")}
                    purpose={"external"}
                    to={links.v2.safe}
                  />
                  <Button
                    accent={"dark150"}
                    appearance={"outline"}
                    title={t("landing.develop.explore.actions.request")}
                    purpose={"external"}
                    to={links.canny}
                  />
                </Actions>
              </Group>
            </Box>
          </Cell>
          <Cell>
            <Center>
              <Hourglass speed={8}>
                <Image
                  alt={"Hourglass"}
                  src={AssetHourglass}
                  fill
                  sizes={
                    "(min-width: 300px) 50vw,(min-width: 1000px) 20vw, 100vw"
                  }
                />
              </Hourglass>
            </Center>
          </Cell>
          <Cell>
            <Box>
              <Heading>
                <p>{t("landing.develop.validate.title")}</p>
              </Heading>
              <Group>
                <Title value={t("landing.develop.validate.audit.title")} />
                <Description>
                  <p>{t("landing.develop.validate.audit.description")}</p>
                </Description>
              </Group>
              <Group>
                <Title value={t("landing.develop.validate.tested.title")} />
                <Description>
                  <p>{t("landing.develop.validate.tested.description")}</p>
                </Description>
              </Group>

              <Group>
                <Actions>
                  <Button
                    accent={"dark150"}
                    appearance={"outline"}
                    title={t("landing.develop.validate.actions.audit")}
                    purpose={"external"}
                    to={links.security.audits}
                  />
                </Actions>
              </Group>
            </Box>
          </Cell>
          <Cell>
            <Shielded>
              <Shield speed={4}>
                <Image
                  alt={"Shield"}
                  src={AssetShield}
                  fill
                  sizes={
                    "(min-width: 300px) 50vw,(min-width: 1000px) 20vw, 100vw"
                  }
                  quality={100}
                />
              </Shield>
            </Shielded>
            <Box>
              <Heading>
                <p>{t("landing.develop.integrate.title")}</p>
              </Heading>
              <Group>
                <Description>
                  <p>{t("landing.develop.integrate.description")}</p>
                </Description>
              </Group>

              <Group>
                <Actions>
                  <Button
                    accent={"dark150"}
                    appearance={"outline"}
                    title={t("words.documentation")}
                    purpose={"external"}
                    to={links.docs.v2}
                  />
                  <Row>
                    <Button
                      accent={"dark150"}
                      appearance={"outline"}
                      title={t("words.github")}
                      purpose={"external"}
                      to={links.github.organization}
                    />
                    <Button
                      accent={"dark150"}
                      appearance={"outline"}
                      title={t("words.discord")}
                      purpose={"external"}
                      to={links.discord}
                    />
                  </Row>
                </Actions>
              </Group>
            </Box>
          </Cell>
        </Grid>
      </Section>
      <Underlay />
    </Wrapper>
  );
}

export default Develop;
